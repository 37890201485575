import "!style-loader!css-loader?{\"modules\":false,\"url\":false}!./Select.treat.2-2dBB-.css";
export var wrapper = '_1iWbp';
export var wrapperColor = {blue:'_3AfiP',white:'_1JGMv'};
export var valueContainer = '_2dTtz';
export var placeholder = '_24sRJ';
export var placeholderPadding = 'uF_pQ';
export var placeholderSizes = {sm:'_1DGJK',md:'_1KSGn'};
export var input = '_78yVQ';
export var inputSize = {sm:'_3tuxF',md:'_3Mle-'};
export var errorMessage = '_1ruDk';
export var hasError = '_1Y03p';
export var containerDisabled = '_3Osov';
export var container = '_11lcv';
export var containerSizes = {sm:'-rxh-',md:'_3qOfE'};
export var icon = '_3Hurv';
export var label = '_2phnF';
export var labelSizes = {sm:'_1Khhh',md:'_3N6Sx'};
export var singleValue = 'm1HLh';
export var singleValueSizes = {sm:'_3avdg',md:'_2KPIp'};
export var indicatorsContainer = '_1WQbc';
export var dropdownIndicator = '_3X0VU';
export var menu = 'xuX_W';
export var option = '_3JXSn';
export var optionSizes = {sm:'_1VK1K',md:'_2x6Qu'};
export var isRequiredStar = 'Wz8PR';